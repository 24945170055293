import { Box, Button, TextField, Checkbox, FormControlLabel, Typography, Link } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, setPersistence, browserLocalPersistence, browserSessionPersistence, sendEmailVerification, signOut } from "firebase/auth";
import { useRef, useState } from "react";
import { useTranslation } from "../../contexts/TranslationContext";

function SigninPanel() {
	const { translate } = useTranslation();
	const [sending, setSending] = useState<boolean>(false);
	const [isEmailError, setIsEmailError] = useState<boolean>(false);
	const [isPasswordError, setIsPasswordError] = useState<boolean>(false);
	const [rememberMe, setRememberMe] = useState<boolean>(false);
	const [message, setMessage] = useState<string>("");
	const [email, setEmail] = useState("");

	const formRef = useRef<HTMLFormElement>(null);

	const theme = useTheme();

	const handleSignin = async (event: any) => {
		setSending(true);
		setIsEmailError(false);
		setIsPasswordError(false);
		setMessage("");
		event.preventDefault();
		const { email, password } = event.target.elements;
		const auth = getAuth();

		await setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence);

		signInWithEmailAndPassword(auth, email.value, password.value)
			.then((userCredential) => {
				setSending(false);
				if (!!userCredential.user && !userCredential.user.emailVerified) {
					sendEmailVerification(userCredential.user);
					signOut(auth);
					setMessage(translate("[login_alertverification]"));
				}
			})
			.catch((error) => {
				setIsEmailError(true);
				setIsPasswordError(true);
				setSending(false);
				setMessage(translate("[login_incorrect]"));
			});
	};

	const sendPasswordReset = async () => {
		const auth = getAuth();

		setMessage("");

		if (!email) {
			setIsEmailError(true);
			setIsPasswordError(false);
			setMessage(translate("[login_enteremail]"));
			return;
		}

		setIsEmailError(false);
		sendPasswordResetEmail(auth, email)
			.then(() => {
				setMessage(`${translate("[login_recoverysent]")} ${email}`);
			})
			.catch((error) => {
				setIsEmailError(true);
				setMessage(translate("[login_cantrecover]"));
			});
	};

	return (
		<Box>

			<Typography variant="body2" style={{ textAlign: "center", minHeight: '2em' }}
				paddingX={2}
				color={isEmailError ? theme.palette.error.main : theme.palette.text.primary} >
				{!!message ? message : translate("[login_enteremail]")}
			</Typography>

			<Box
				display="flex"
				flexDirection="column"
				component="form"
				alignItems="center"
				onSubmit={(event: any) => handleSignin(event)}
				ref={formRef}
				gap={2}
				margin={2}
			>
				<TextField
					fullWidth
					name="email"
					label={translate("[login_email_title]")}
					variant="outlined"
					type="email"
					autoComplete="email"
					required
					error={isEmailError}

					onChange={(e) => setEmail(e.target.value)}
				/>

				<TextField
					fullWidth
					name="password"
					label={translate("[login_password_title]")}
					variant="outlined"
					type="password"
					autoComplete="current-password"
					required
					error={isPasswordError}
				/>
				<Button type="submit" variant="contained" color="primary" disabled={sending} name="signin" fullWidth>
					{translate("[login_enter]")}
				</Button>

				<Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
					<FormControlLabel
						control={
							<Checkbox
								checked={rememberMe}
								onChange={(e) => setRememberMe(e.target.checked)}
								name="rememberme"
							/>
						}
						label={
							<Typography variant="caption">
								{translate("[login_remember]")}
							</Typography>
						}
					/>
					<Link href="#" variant="caption" onClick={sendPasswordReset}>
						{translate("[login_forgot]")}
					</Link>
				</Box>
			</Box>
		</Box >
	);
}


export default SigninPanel;