import { Box, Button, TextField, Checkbox, FormControlLabel, Typography, Link } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { useRef, useState } from "react";
import { useTranslation } from "../../contexts/TranslationContext";
import { sendEmail, subscribeNewsletter } from "../../adapters/Firebase";

function SignupPanel() {
    const { translate } = useTranslation();
    const [sending, setSending] = useState<boolean>(false);
    const [isEmailError, setIsEmailError] = useState<boolean>(false);
    const [isPasswordError, setIsPasswordError] = useState<boolean>(false);
    const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
    const [subscribe, setSubscribe] = useState<boolean>(true);
    const [message, setMessage] = useState<string>("");

    const formRef = useRef<HTMLFormElement>(null);

    const theme = useTheme();

    const handleSignup = async (event: any) => {
        setSending(true);
        setIsEmailError(false);
        setIsPasswordError(false);
        setMessage("");
        event.preventDefault();
        const { email, password } = event.target.elements;
        const subscribeToo = subscribe;
        const auth = getAuth();

        if (!email || !password) {
            setIsEmailError(!email);
            setIsPasswordError(!password);
            setMessage(translate("[login_enteremail]"));
            return;
        }

        createUserWithEmailAndPassword(auth, email.value, password.value)
            .then((userCredential) => {
                setSending(false);
                if (!userCredential.user.emailVerified) {
                    sendEmailVerification(userCredential.user);
                    setMessage(translate("[login_alertverification]"));
                }

                if(subscribeToo){
                   subscribeNewsletter(email.value);
                }

                sendEmail("[Nueva cuenta]", email.value,
                    "Este email es automático. " + 
                    "El usuario " + email.value + " se ha registrado en la plataforma. " 
                    + "También ha solicitado " + (subscribeToo ? "suscribirse al boletín." : "no suscribirse al boletín."));
                
            })
            .catch((error) => {
                setIsEmailError(true);
                setIsPasswordError(true);
                setSending(false);
                setMessage(translate("[login_incorrect]"));
            });
    };

    return (
        <Box>
            <Typography variant="body2" style={{ textAlign: "center", minHeight: '2em' }}
                paddingX={2}
                color={isEmailError ? theme.palette.error.main : theme.palette.text.primary} >
                {!!message ? message : translate("[login_enteremail_signup]")}
            </Typography>

            <Box
                display="flex"
                flexDirection="column"
                component="form"
                alignItems="center"
                onSubmit={(event: any) => handleSignup(event)}
                ref={formRef}
                gap={2}
                margin={2}
            >
                <TextField
                    fullWidth
                    name="email"
                    label={translate("[login_email_title]")}
                    variant="outlined"
                    type="email"
                    autoComplete="email"
                    required
                    error={isEmailError}
                />

                <TextField
                    fullWidth
                    name="password"
                    label={translate("[login_password_title]")}
                    variant="outlined"
                    type="password"
                    autoComplete="current-password"
                    required
                    error={isPasswordError}
                />

                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={subscribe}
                                onChange={(e) => setSubscribe(e.target.checked)}
                                name="subscribe"
                            />
                        }
                        label={
                            <Box display="flex" flexDirection="row" gap={1}>
                                <Typography variant="caption">
                                    {translate("[login_subscribe]")}
                                </Typography>
                            </Box>}
                    />
                </Box>

                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={acceptTerms}
                                onChange={(e) => setAcceptTerms(e.target.checked)}
                                name="acceptterms"
                            />
                        }
                        label={
                            <Box display="flex" flexDirection="row" gap={1}>
                                <Typography variant="caption">
                                    {translate("[login_acceptterms]")}
                                </Typography>
                                <Link href="/privacy_policy" variant="caption"
                                    display="flex" justifyContent="center">
                                    {translate("[sidebar_privacypolicy]")}
                                </Link>
                            </Box>}
                    />
                </Box>
                <Button type="submit" variant="contained" color="primary" disabled={sending || !acceptTerms} name="signup" fullWidth>
                    {translate("[signup_create]")}
                </Button>

            </Box>
        </Box >
    );
}


export default SignupPanel;