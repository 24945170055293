import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import Logo from "../../media/logo";
import LogoText from "../../media/logoText";
import SigninPanel from "./SigninPanel";
import { useTranslation } from '../../contexts/TranslationContext';
import SignupPanel from './SignupPanel';

interface TabPanelProps {
	children?: React.ReactNode;
	tab: string;
	value: string;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, tab, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== tab}
			id={`simple-tabpanel-${tab}`}
			aria-labelledby={`simple-tab-${tab}`}
			{...other}
		>
			{value === tab && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

function SignInScreen() {
	const { translate } = useTranslation();
	const theme = useTheme();
	const [activeTab, setActiveTab] = useState("signin");

	useEffect(() => {
		document.body.style.backgroundColor = theme.palette.primary.main;
		return () => {
			document.body.style.backgroundColor = theme.palette.background.default;
		};
	}, [theme.palette.background.default, theme.palette.primary.main])

	return (
		<>
			<Box style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', gap: '30px', }}>
				<Box style={{ borderRadius: "50%", backgroundColor: `${theme.palette.background.paper}` }}>
					<Logo height="40" width="40" style={{ margin: 5 }} />
				</Box>
				<LogoText height="60" width="260" fill={theme.palette.background.paper} style={{ alignself: "center" }} />
			</Box>
			<br />
			<br />
			<Typography variant="h2" style={{ textAlign: "center" }} color={theme.palette.background.paper}>
				{translate("[login_title]")}
			</Typography>
			<br />

			<Box display="flex" justifyContent="center" >
				<Paper elevation={3}
					style={{ maxWidth: 500, width: '100%' }}>
					<Box width="100%" display="flex" justifyContent="center">
						<Tabs
							value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}
							textColor="primary"
							indicatorColor="primary"
							aria-label="login tabs">
							<Tab value="signin" label={translate("[login_signin_tab]")} />
							<Tab value="signup" label={translate("[login_signup_tab]")} />
						</Tabs>
					</Box>

					<TabPanel value={activeTab} tab="signin" >
						<SigninPanel />
					</TabPanel>
					<TabPanel value={activeTab} tab="signup">
						<SignupPanel />
					</TabPanel>
				</Paper>
			</Box>


		</>
	);
}

export default SignInScreen